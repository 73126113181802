<template>
  <v-container class="fill-height mt-5 mb-10" :class="{ 'mt-12': $vuetify.breakpoint.mdAndDown }">
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12">
        <CreditCard :fields="fields" :labels="creditCard" class="mb-n15" @card-type="cardType" />
      </v-col>
      <v-col cols="12" md="8" xl="6">
        <v-card class="rounded-xl pb-3" elevation="10" style="padding-top: 80px">
          <v-card-text>
            <v-form ref="form" @submit="$v.touch()">
              <div class="d-flex">
                <v-text-field
                  :id="fields.holderName"
                  v-model="creditCard.holderName"
                  outlined
                  label="Nome completo do titular do cartão"
                  :rules="[
                    () => $v.creditCard.holderName.required || 'Campo obrigatório',
                    () => $v.creditCard.holderName.regex || 'Apenas caracteres são aceitos',
                    () => $v.creditCard.holderName.validateSurname || 'Digite pelo menos um sobrenome',
                  ]"
                ></v-text-field>
                <v-text-field
                  class="ml-5"
                  v-model="creditCard.holderMail"
                  outlined
                  label="Email do titular do cartão"
                  :rules="[
                    () => $v.creditCard.holderMail.required || 'Campo obrigatório',
                    () => $v.creditCard.holderMail.email || 'Email inválido',
                  ]"
                ></v-text-field>
              </div>
              <v-text-field
                v-model="cpfCnpj"
                outlined
                label="CPF/CNPJ do titular do cartão"
                :rules="[
                  () => $v.cpfCnpj.required || 'Campo obrigatório',
                  () => $v.cpfCnpj.validCpfCnpj || 'CPF/CNPJ inválido',
                ]"
                v-mask="[cpfMask, cnpjMask]"
              ></v-text-field>
              <v-text-field
                :id="fields.number"
                v-model="creditCard.number"
                outlined
                label="Número do cartão"
                v-mask="cardMask"
                :rules="[
                  () => $v.creditCard.number.required || 'Campo obrigatório',
                  () => $v.creditCard.number.minLength || 'Número do cartão inválido',
                  () => $v.creditCard.number.validateCardNumber || 'Número do cartão inválido',
                ]"
              ></v-text-field>
              <div class="d-flex">
                <v-text-field
                  v-model="creditCard.holderPostalCode"
                  outlined
                  label="Código Postal"
                  v-mask="'#####-###'"
                  :rules="[() => $v.creditCard.holderPostalCode.required || 'Campo obrigatório']"
                ></v-text-field>
                <v-text-field
                  class="ml-5"
                  v-model="creditCard.holderAddressNumber"
                  outlined
                  label="Número do Endereço"
                  :rules="[
                    () => $v.creditCard.holderAddressNumber.required || 'Campo obrigatório',
                    () => $v.creditCard.holderAddressNumber.numeric || 'Digite um numero válido',
                  ]"
                ></v-text-field>
                <v-text-field
                  class="ml-5"
                  v-model="creditCard.holderMobilePhone"
                  outlined
                  label="Número de Celular"
                  v-mask="'(##) #####-####'"
                  :rules="[
                    () => $v.creditCard.holderMobilePhone.required || 'Campo obrigatório',
                    () => $v.creditCard.holderMobilePhone.minLength || 'Número de celular inválido',
                  ]"
                ></v-text-field>
              </div>
              <div class="d-flex">
                <v-text-field
                  :id="fields.expiryDate"
                  v-model="creditCard.expiryDate"
                  outlined
                  label="Data de vencimento"
                  v-mask="'##/####'"
                  placeholder="MM/AAAA"
                  :rules="[
                    () => $v.creditCard.expiryDate.required || 'Campo obrigatório',
                    () => $v.creditCard.expiryDate.validDate || 'Data inválida',
                    () => $v.creditCard.expiryDate.minLength || 'Data inválida',
                    () => $v.creditCard.expiryDate.isNotExpired || 'O cartão informado está expirado',
                  ]"
                ></v-text-field>
                <v-text-field
                  class="ml-5"
                  :id="fields.cvv"
                  v-model="creditCard.cvv"
                  outlined
                  data-card-field
                  label="Código de segurança"
                  v-mask="'####'"
                  :rules="[
                    () => $v.creditCard.cvv.required || 'Campo obrigatório',
                    () => $v.creditCard.cvv.minLength || 'CVV inválido',
                  ]"
                ></v-text-field>
              </div>
              <v-select v-model="installmentCount" outlined label="Forma de pagamento" :items="parcelas"></v-select>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row no-gutters justify="center" align="center">
              <v-btn depressed color="success" rounded x-large @click="nextStep()" :loading="loading">
                <span>Confirmar a compra</span>
                <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { parse, isValid, isFuture, isThisMonth } from 'date-fns'
import { validationMixin } from 'vuelidate'
import { required, minLength, email, numeric } from 'vuelidate/lib/validators'
import CreditCard from '@/components/common/CreditCard.vue'
import { cpfMask, cnpjMask } from '@/utils/maskHelpers'
import validaCnpj from '@/utils/validaCnpj'
import validaCpf from '@/utils/validaCpf'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import { extractOnlyNumbers } from '@/utils/formatter'

export default {
  mixins: [validationMixin, agendamentoMixin],
  components: {
    CreditCard,
  },
  validations: {
    cpfCnpj: {
      required,
      validCpfCnpj(cpfCnpj) {
        return (cpfCnpj && validaCpf(cpfCnpj)) || (cpfCnpj.length > 2 && validaCnpj(cpfCnpj))
      },
    },
    creditCard: {
      holderName: {
        required,
        regex(holderName) {
          const regex = /^[a-zA-Z\u00C0-\u017F\s]+$/
          return regex.test(holderName)
        },
        validateSurname(holderName) {
          if (holderName) {
            const words = holderName.trim().split(' ')
            return words.length >= 2
          }
          return false
        },
      },
      holderMail: { required, email },
      holderAddressNumber: { required, numeric },
      holderPostalCode: { required },
      holderMobilePhone: { required, minLength: minLength(15) },
      number: {
        required,
        minLength: minLength(16),
        validateCardNumber(number) {
          const clearNumber = number.replace(/\s+/g, '').replace(/-/g, '')

          // Referência de lógica de validação: https://stackoverflow.com/questions/6176802/how-to-validate-a-credit-card-number
          let s = 0
          let doubleDigit = false

          // Percorrer o número do cartão da direita para a esquerda
          for (let i = clearNumber.length - 1; i >= 0; i--) {
            let digit = +clearNumber[i]
            if (doubleDigit) {
              digit *= 2

              // Se o dobro for maior que 9, subtrai 9
              if (digit > 9) digit -= 9
            }
            s += digit
            doubleDigit = !doubleDigit
          }

          // O número do cartão é válido se a soma total for divisível por 10
          return s % 10 === 0
        },
      },
      expiryDate: {
        required,
        minLength: minLength(7),
        validDate(date) {
          return isValid(parse(date, 'MM/yyyy', this.refDate))
        },
        isNotExpired(date) {
          const parsed = parse(date, 'MM/yyyy', this.refDate)
          return isThisMonth(parsed) || isFuture(parsed)
        },
      },
      cvv: {
        required,
        minLength: minLength(3),
      },
    },
  },
  data() {
    return {
      cardBrand: null,
      loading: false,
      refDate: new Date(),
      cpfCnpj: '',
      installmentCount: null,
      creditCard: {
        holderName: '',
        number: '',
        expiryDate: '',
        cvv: '',
      },
      fields: {
        holderName: 'v-card-number',
        number: 'v-card-name',
        expiryDate: 'v-card-date',
        cvv: 'v-card-cvv',
      },
      cpfMask,
      cnpjMask,
      parcelas: [
        { value: null, text: 'À Vista' },
        { value: 2, text: '2 Vezes' },
        { value: 3, text: '3 Vezes' },
        { value: 4, text: '4 Vezes' },
      ],
    }
  },
  methods: {
    ...mapActions('agendamento', ['finishAgendamentoExpress']),

    buildCreditCardInfo() {
      const [expiryMonth, expiryYear] = this.creditCard.expiryDate.split('/')

      return {
        creditCard: {
          holderName: this.creditCard.holderName,
          number: extractOnlyNumbers(this.creditCard.number),
          ccv: this.creditCard.cvv,
          expiryMonth,
          expiryYear,
        },
        creditCardHolderInfo: {
          name: this.creditCard.holderName,
          email: this.creditCard.holderMail,
          cpfCnpj: this.cpfCnpj,
          postalCode: this.creditCard.holderPostalCode,
          addressNumber: this.creditCard.holderAddressNumber,
          mobilePhone: extractOnlyNumbers(this.creditCard.holderMobilePhone),
        },
        installmentCount: this.installmentCount,
      }
    },
    async nextStep() {
      if (!this.$refs.form.validate()) return

      const creditCardInfo = this.buildCreditCardInfo()

      this.loading = true
      try {
        const { data } = await this.finishAgendamentoExpress(creditCardInfo)
        this.actualStepWrapper = 8
        this.$router.push({ name: 'agendamento-sucesso', params: { paymentId: data.paymentId } })
      } catch (error) {
        console.log(error)
        this.showErrorOnSnackbar(error.response.data.message)
      } finally {
        this.loading = false
      }
    },
    cardType(val) {
      this.cardBrand = val
    },
  },
  computed: {
    cardMask() {
      if (['mastercard', 'visa'].includes(this.cardBrand)) {
        return '#### #### #### ####'
      }

      if (['amex'].includes(this.cardBrand)) {
        return '#### ###### #####'
      }

      if (['dinersclub'].includes(this.cardBrand)) {
        return '#### ###### ####'
      }

      return '#### #### #### ####'
    },
  },
}
</script>
