<template>
  <div class="container" :class="{ 'mt-10': $vuetify.breakpoint.mdAndDown }">
    <div class="d-flex flex-column align-center mt-15">
      <div class="head-title">Precisamos de mais alguns dados</div>
      <div class="subtitle mt-3">Informe a quantidade de funcionários e qual segmento sua empresa está atuando</div>
    </div>
    <v-form ref="form" @submit.prevent>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="10" class="mb-4 mt-15"> Número de funcionários: </v-col>
        <v-col cols="10">
          <v-text-field
            label="Digite a quantidade de funcionários na sua empresa"
            outlined
            v-model="qtdeFuncionarios"
            :rules="[v => !!v || 'Campo obrigatório', v => v > 0 || 'Valor inválido']"
            v-mask="'#####'"
          ></v-text-field>
        </v-col>

        <v-col cols="10" class="mb-5"> Escolha o segmento: </v-col>
        <v-col cols="10">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              xl="3"
              v-for="segmento in getSegmentos"
              :key="segmento.id"
              class="mb-2"
            >
              <v-hover>
                <template v-slot="{ hover }">
                  <v-card
                    class="pa-4"
                    rounded
                    hover
                    outlined
                    :elevation="hover ? 10 : 2"
                    :class="{ 'segmentCard--active': segmentoSelected === segmento.id }"
                    @click="segmentoSelected = segmento.id"
                  >
                    <v-row no-gutters>
                      <div class="icon-checked" v-if="segmentoSelected === segmento.id">
                        <v-icon color="success">mdi-check</v-icon>
                      </div>
                      <v-col cols="12">
                        <v-icon color="primary" size="28">{{ segmentoIcons[segmento.nome] }}</v-icon>
                      </v-col>
                      <v-col cols="12" class="text-subtitle-2 mt-3" style="color: grey">
                        {{ segmento.nome }}
                      </v-col>
                    </v-row>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10">
          <v-row class="float-right mt-8 mb-15">
            <v-btn color="primary" rounded x-large class="elevation-12" @click="nextStep" :disabled="!getSegmento">
              <span class="capitalize">Seguir</span>
              <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import autenticacaoService from '@/services/autenticacao'

export default {
  mixins: [agendamentoMixin],
  data() {
    return {
      segmentoIcons: {
        'Restaurantes e bares': 'mdi-food',
        Comércio: 'mdi-cart-outline',
        'Escritório Administrativo': 'mdi-office-building-outline',
        TI: 'mdi-wifi',
        Terceirização: 'mdi-shield-outline',
        Educação: 'mdi-school-outline',
        Construção: 'mdi-wrench-outline',
        'Pequenos negócios': 'mdi-store',
        Indústria: 'mdi-factory',
        Agricultura: 'mdi-tractor',
      },
    }
  },
  computed: {
    ...mapGetters('agendamento/empresa', ['getSegmento', 'getSegmentos', 'getQtdeFuncionarios']),
    segmentoSelected: {
      get() {
        return this.getSegmento
      },
      set(value) {
        this.setSegmento(value)
      },
    },
    qtdeFuncionarios: {
      get() {
        return this.getQtdeFuncionarios
      },
      set(value) {
        this.setQtdeFuncionarios(value)
      },
    },
  },
  methods: {
    ...mapMutations('agendamento/empresa', ['setSegmento', 'setQtdeFuncionarios']),

    async nextStep() {
      if (!this.$refs.form.validate()) return

      if (autenticacaoService.isAuthenticated()) {
        this.actualStepWrapper = 6
        this.$router.push({ name: 'colaborador' })
      } else {
        this.actualStepWrapper = 5
        this.$router.push({ name: 'agendamento-perfil' })
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 4
  },
}
</script>

<style lang="scss">
.segmentCard {
  &--active {
    border-color: #006bf2 !important;
  }
}
.icon-checked {
  top: 0;
  right: 0;
  position: absolute;
  margin-right: 10px;
  margin-top: 5px;
}
</style>
