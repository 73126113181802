<template>
  <v-row no-gutters justify="center">
    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="12">
          <h3>Dados do colaborador</h3>
        </v-col>
        <v-col cols="10" class="text">
          <p>
            Nome: {{ funcionario.nome }} <span class="ml-5">CPF: {{ funcionario.cpf }}</span>
            <span class="ml-5" v-if="funcionario.rg">RG: {{ funcionario.rg }}</span>
          </p>
          <p>
            Data Nascimento: {{ funcionario.dt_nascimento }}
            <span class="ml-5">Data Admissão: {{ funcionario.dt_contratacao }}</span>
          </p>
          <p>
            Função: <span class="capitalize mr-5"> {{ funcionario.funcao.toLowerCase() }} </span>

            Setor: <span class="capitalize mr-5"> {{ funcionario.setor.toLowerCase() }} </span>

            CBO: <span class="capitalize"> {{ funcionario.cbo.toLowerCase() }} </span>
          </p>
        </v-col>
        <v-col cols="3" md="2">
          <v-row no-gutters align="center" class="fill-height">
            <v-btn outlined fab color="primary" @click="editFuncionarioData(funcionario.cpf)">
              <v-icon>mdi-lead-pencil</v-icon>
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-alert dense text color="primary" class="text pa-5">
            Abaixo está o protocolo pré definido pelo médico coordenador para essa função.
          </v-alert>
        </v-col>
      </v-row>

      <AgendamentoProtocolo :funcionario="funcionario" @change-step="$emit('change-step', $event)" />
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import AgendamentoProtocolo from './AgendamentoProtocolo.vue'

export default {
  props: {
    funcionario: {
      required: true,
    },
  },
  mixins: [agendamentoMixin],
  components: { AgendamentoProtocolo },
  computed: {
    ...mapState('agendamento', ['idClinica']),
  },
  methods: {
    ...mapMutations('agendamento/funcionarios', [
      'addFuncionario',
      'setExames',
      'setRiscos',
      'setCurrentFuncionarioCpf',
    ]),
    editFuncionarioData() {
      this.$emit('change-step', 1)
    },
    nextStep() {
      this.actualStepWrapper = 6
      this.$router.push({ name: 'colaborador' })
    },
  },
}
</script>

<style scoped>
.text {
  font-size: 0.875rem;
}

@media (max-width: 960px) {
  .text p {
    display: flex;
    flex-direction: column;
  }
  .text span {
    margin-top: 8px;
  }
}
</style>
