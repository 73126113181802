<template>
  <v-main class="container-resumo fill-height" :class="{ 'mt-12': $vuetify.breakpoint.mdAndDown }">
    <v-row class="fill-height" justify="center" align-content="center">
      <div v-if="getFuncionarios.length === 0">
        <AdicionarColaborador />
      </div>
      <div v-else class="d-flex align-center justify-center flex-column gap flex">
        <div class="head-title">Resumo do agendamento</div>
        <div class="subtitle">Resumo dos agendamentos solicitados</div>
        <div class="align-self-end my-8">
          <v-chip large class="px-8" color="#efefff">
            <v-icon color="#4511ff" class="mr-5" large>mdi-cart-outline</v-icon>
            <span class="capitalize mr-5"> Total: </span>
            <span class="prize">{{ getValorTotalExames }}</span>
          </v-chip>
        </div>
        <ColaboradorResumoCard
          class="my-5"
          v-for="(funcionario, index) in getFuncionarios"
          :key="index"
          :funcionario="funcionario"
        />
        <div class="adicionar-colaboradores pointer mt-5" @click="addColaborador()">Adicionar mais colaboradores</div>
      </div>
    </v-row>
    <v-row v-if="getFuncionarios.length > 0" no-gutters justify="center">
      <v-col cols="12">
        <v-row no-gutters class="pr-6 mb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            rounded
            x-large
            class="elevation-12"
            @click="nextStep"
            :disabled="someFuncionarioIsInvalid"
          >
            <span class="capitalize">Seguir</span>
            <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import AdicionarColaborador from './AdicionarColaborador.vue'
import ColaboradorResumoCard from './components/ColaboradorResumoCard.vue'

export default {
  name: 'ColaboradorResumo',
  components: {
    ColaboradorResumoCard,
    AdicionarColaborador,
  },
  computed: {
    ...mapGetters('agendamento', ['tipoAgendamento']),
    ...mapGetters('agendamento/funcionarios', ['getFuncionarios', 'getValorTotalExames']),
    ...mapGetters('agendamento/empresa', ['getEmpresaTemFaturamento']),
    someFuncionarioIsInvalid() {
      return (
        this.tipoAgendamento === 'online' &&
        !isEmpty(this.getFuncionarios.filter(funcionario => !(funcionario.clinica?.idClinica || funcionario.idHorario)))
      )
    },
  },
  mixins: [agendamentoMixin],
  methods: {
    nextStep() {
      this.actualStepWrapper = 7
      this.$router.push({ name: 'agendamento-pagamento' })
    },
    addColaborador() {
      this.actualStepWrapper = 6
      this.$router.push({ name: 'colaborador-dados' })
    },
  },
  beforeMount() {
    this.actualStepWrapper = 6
  },
}
</script>

<style scoped>
.container-resumo {
  margin: 0% 8%;
}
.adicionar-colaboradores {
  color: #4511ff;
}
</style>
