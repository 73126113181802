<template>
  <v-container class="fill-height escolher-agenamento" :class="{ 'mt-12': $vuetify.breakpoint.mdAndDown }" style="max-width: 800px">
    <v-row class="fill-height" justify="center" align-content="center">
      <div class="ma-5" :class="{ 'width-60': $vuetify.breakpoint.mdAndUp }">
        <v-radio-group v-model="selectedTipoAtendimento" :column="false">
          <div class="d-flex flex flex-row justify-space-between">
            <v-radio label="Atendimento presencial" value="presencial"></v-radio>
            <v-radio label="Teleatendimento" value="online"></v-radio>
          </div>
        </v-radio-group>
      </div>
      <div v-if="selectedTipoAtendimento === 'online'" class="selected-attendance">
        Você escolheu atendimento online
      </div>
      <div v-else-if="selectedTipoAtendimento === 'presencial'" class="selected-attendance">
        Você escolheu atendimento presencial na clínica
      </div>
      <v-col cols="12" style="height: 40%">
        <template v-if="selectedTipoAtendimento === 'presencial'">
          <AgendamentoCidades ref="AgendamentoCidade" />
        </template>
        <div v-else>
          <h5 class="head-title text-center">Agendar exame online</h5>
          <p class="subtitle text-center mb-15">
            Você esta selecionando um atendimento online,<br />
            clique em seguir para agendar.
          </p>
        </div>
      </v-col>
      <v-col>
        <v-btn color="#005DFF" dark rounded x-large depressed class="elevation-12 float-right mt-10" @click="nextStep">
          <span class="capitalize">Seguir</span>
          <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import CustomAutocomplete from '@/components/common/CustomAutoComplete.vue'
import AgendamentoCidades from '@/components/Agendamento/AgendamentoCidades'

export default {
  name: 'EscolherAgendamento',
  mixins: [agendamentoMixin],
  data: () => ({
    atendimento: 1,
    loading: false,
  }),
  components: {
    CustomAutocomplete,
    AgendamentoCidades,
  },
  computed: {
    ...mapGetters('agendamento', ['cidade']),
    ...mapGetters('agendamento', ['tipoAgendamento']),
    selectedTipoAtendimento: {
      get() {
        return this.tipoAgendamento
      },
      set(value) {
        this.SET_TIPO_AGENDAMENTO(value)
      },
    },
  },
  methods: {
    ...mapMutations('agendamento', ['SET_TIPO_AGENDAMENTO']),
    resolveLoading(value) {
      this.loading = value
    },
    nextStep() {
      if (this.selectedTipoAtendimento === 'presencial') {
        if (
          this.$refs.AgendamentoCidade.$refs.AgendamentoCidadeForm.validate() &&
          this.$refs.AgendamentoCidade.verificaCidadeSemClinicas()
        ) {
          this.actualStepWrapper = 2
          this.$router.push({ name: 'agendamento-clinicas' })
        }
      } else if (this.selectedTipoAtendimento === 'online') {
        this.actualStepWrapper = 3
        this.$router.push({ name: 'empresa-cnpj' })
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 1
  },
}
</script>

<style lang="css" scoped>
.gap {
  column-gap: 2rem;
}
.v-input--switch >>> .v-input--switch__thumb {
  background-color: white !important;
  color: white;
}
.v-input--switch >>> .v-input--switch__track {
  border-radius: 14px;
  height: 28px;
  left: -4px;
  opacity: 1;
  top: calc(50% - 14px);
}

.selected-attendance {
  background-color: #f3f8ff;
  color: #3976ff;
  width: 100%;
  border-radius: 5px;
  padding: 8px 0px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 75px;
  margin-top: 20px;
}

.escolher-agenamento >>> .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #005dff4a !important;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
</style>
