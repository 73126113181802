<template>
  <v-container class="fill-height">
    <v-row no-gutters align="center" justify="center">
      <v-card :width="$vuetify.breakpoint.mdAndDown ? '100%' : '700'" class="rounded-xl elevation-14" loading>
        <template slot="progress">
          <v-progress-linear color="primary" height="30" :value="progressBar()">
            <strong class="white--text timer" v-if="!loading && timer">
              {{ timerFormatted }}
            </strong>
          </v-progress-linear>
        </template>

        <div class="d-flex flex-no-wrap justify-space-between py-3 px-2" :class="{ 'flex-column': $vuetify.breakpoint.mdAndDown }">
          <div class="d-flex align-center" :class="{ 'justify-center': $vuetify.breakpoint.mdAndDown }">
            <v-avatar class="ma-3" size="250" tile>
              <v-img :src="src">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </div>

          <div class="overflow-auto d-flex flex-column justify-space-between" :class="{ 'mt-4': $vuetify.breakpoint.mdAndDown }">
            <v-card-title class="mb-3 text-center">
              <strong>Aguardando pagamento</strong>
            </v-card-title>
            <v-card-subtitle class="text-center">
              Você tem até 10 minutos para fazer o pagamento. Após esse tempo, o atendimento será cancelado.
            </v-card-subtitle>
            <v-card-subtitle class="text-center">
              Faça a leitura do QR Code ao lado ou copie o código abaixo para efetuar o pagamento.
            </v-card-subtitle>
            <v-card-text>
              <v-alert outlined text color="primary" style="word-break: break-word">
                <strong>{{ code }}</strong>
              </v-alert>
            </v-card-text>
          </div>
        </div>

        <v-card-actions>
          <v-btn block color="primary" depressed rounded x-large @click="copyToClipboard">
            Copiar código
            <v-icon size="20" right>mdi-content-copy</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { subSeconds, format, intervalToDuration, isAfter } from 'date-fns'
import pagamentoService from '@/services/pagamento'
import agendamentoMixin from '@/mixins/agendamentoMixin'

export default {
  mixins: [agendamentoMixin],
  props: ['paymentId'],
  data() {
    return {
      src: '',
      code: '',
      eventSource: null,
      timer: null,
      timerInterval: null,
      loading: false,
      createdDate: null,
      expirationDate: null,
    }
  },
  computed: {
    timerFormatted() {
      return format(this.timer, 'mm:ss')
    },
  },
  methods: {
    async getPixQrCode() {
      this.loading = true
      try {
        const { data } = await pagamentoService.getPixQrCode(this.paymentId)
        this.src = `data:image/png;base64,${data.encodedImage}`
        this.code = data.payload
        this.listenToPixPayment()
        this.startTimer(data.createdDate, data.expirationDate)
      } catch (error) {
        console.log(error)
        this.showErrorOnSnackbar(error.response.data.message)

        if (error.response.status === 409) {
          setTimeout(() => (window.location.href = error.response.data.redirectTo), 2000)
        }
      } finally {
        this.loading = false
      }
    },
    listenToPixPayment() {
      this.eventSource = pagamentoService.waitingPayment(this.paymentId)

      this.eventSource.onmessage = event => {
        console.log(event)
        if (event.data === 'paga') {
          this.actualStepWrapper = 8
          this.$router.push({ name: 'agendamento-sucesso', params: { paymentId: this.paymentId } })
        }
      }

      this.eventSource.onerror = event => console.error('Asaas Event Source Error: ', event)
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.code)
      this.showSuccessOnSnackbar('Código copiado com sucesso!')
    },
    progressBar() {
      return ((this.expirationDate - Date.now()) / (this.expirationDate - this.createdDate)) * 100
    },
    handlePixExpiration() {
      window.location.reload()
      this.timer = null
      clearInterval(this.timerInterval)
    },
    startTimer(createdDate, expirationDate) {
      this.createdDate = new Date(createdDate)
      this.expirationDate = new Date(expirationDate)

      const interval = intervalToDuration({
        start: new Date(),
        end: new Date(expirationDate),
      })

      this.timer = new Date(2023, 5, 28, 0, interval.minutes, interval.seconds)

      this.timerInterval = setInterval(() => {
        if (isAfter(new Date(), this.expirationDate)) {
          this.handlePixExpiration()
        }

        this.timer = subSeconds(this.timer, 1)
      }, 1000)
    },
  },
  beforeMount() {
    this.actualStepWrapper = 7
    this.getPixQrCode()
  },
  beforeDestroy() {
    this.eventSource.close()
    clearInterval(this.timerInterval)
  },
}
</script>

<style scoped>
.timer {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
}

@media (max-width: 960px) {
  .flex-column {
    flex-direction: column !important;
  }
  .justify-center {
    justify-content: center !important;
  }
  .mt-4 {
    margin-top: 16px !important;
  }
}
</style>
