<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-15" :class="{ 'mt-12': $vuetify.breakpoint.mdAndDown }">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <v-col cols="12">
          <v-row no-gutters align="center" justify="center">
            <v-col cols="12" class="head-title mb-2 text-center"> Indicar colaborador </v-col>
            <v-col cols="12" class="subtitle text-center"> Pra quem você deseja agendar esses exames? </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-stepper v-model="step" class="elevation-0">
        <v-row justify="center">
          <v-stepper-header>
            <v-stepper-step step="1" :complete="step > 1"> </v-stepper-step>
            <v-stepper-step step="2" :complete="step > 2"> </v-stepper-step>
            <v-stepper-step step="3" :complete="step > 3"> </v-stepper-step>
            <v-stepper-step step="4" :complete="step > 4"> </v-stepper-step>
          </v-stepper-header>
        </v-row>

        <v-row justify="center">
          <v-col cols="10">
            <v-stepper-items>
              <v-stepper-content step="1">
                <ColaboradorFirstStep
                  ref="firstForm"
                  v-if="step === 1"
                  :funcionario="funcionario"
                  @update-funcionario="onUpdateFuncionario"
                />
              </v-stepper-content>

              <v-stepper-content step="2">
                <ColaboradorSecondStep
                  v-if="step === 2"
                  ref="secondForm"
                  :funcionario="funcionario"
                  @change-step="step = $event"
                />
              </v-stepper-content>

              <v-stepper-content step="3">
                <ColaboradorQuestionario
                  v-if="step === 3"
                  ref="thirdForm"
                  :funcionario="funcionario"
                  @change-step="step = $event"
                  @current-question="verifyQuestion"
                />
              </v-stepper-content>

              <v-stepper-content step="4">
                <ColaboradorProtocolo
                  v-if="step === 4"
                  ref="thirdForm"
                  :funcionario="funcionario"
                  @change-step="step = $event"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-col>
        </v-row>
      </v-stepper>

      <v-row no-gutters justify="center">
        <v-col cols="10">
          <v-row no-gutters class="pr-6">
            <v-spacer></v-spacer>
            <v-btn :disabled="disableNextButton" color="primary" rounded x-large @click="nextStep" :loading="loading">
              <span class="capitalize">Seguir</span>
              <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <EscolherHorarioDialog
      v-model="escolherHorarioDialog"
      :funcionarioHorario="funcionario.idHorario"
      @select-horario="resolveSelectHorario"
      @confirm-horario="finalizarCadastro()"
    />
    <EscolherClinicaDialog
      v-model="escolherClinicaDialog"
      :funcionario="funcionario"
      @confirm-clinica="finalizarCadastro()"
      @clinica-selected="resolveSelectedClinica"
      @select-cidade="resolveSelectCidade"
    />
  </v-row>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
// eslint-disable-next-line import/no-extraneous-dependencies
import agendamentoMixin from '@/mixins/agendamentoMixin'
import ColaboradorFirstStep from './components/ColaboradorFirstStep.vue'
import ColaboradorSecondStep from './components/ColaboradorSecondStep.vue'
import ColaboradorQuestionario from './components/ColaboradorQuestionario.vue'
import ColaboradorProtocolo from './components/ColaboradorProtocolo.vue'
import EscolherHorarioDialog from './dialogs/EscolherHorarioDialog.vue'
import EscolherClinicaDialog from './dialogs/EscolherClinicaDialog.vue'

export default {
  name: 'ColaboradorDados',
  mixins: [agendamentoMixin],
  components: {
    ColaboradorFirstStep,
    ColaboradorSecondStep,
    ColaboradorQuestionario,
    ColaboradorProtocolo,
    EscolherHorarioDialog,
    EscolherClinicaDialog,
  },
  data: () => ({
    step: 1,
    funcionario: {
      idHorario: null,
      cidade: null,
      clinica: {
        idClinica: null,
        horario: null,
      },
    },
    loading: false,
    escolherHorarioDialog: false,
    escolherClinicaDialog: false,
    idHorario: null,
    lastQuestion: false,
  }),
  computed: {
    ...mapGetters('agendamento/funcionarios', [
      'getFuncionarios',
      'getCurrentFuncionario',
      'getCurrentFuncionarioIndex',
    ]),
    ...mapGetters('agendamento/empresa', ['getEmpresa']),
    ...mapGetters('agendamento', ['tipoAgendamento']),
    disableNextButton() {
      if (this.step === 3) {
        return !this.lastQuestion && !this.$route.query.edit
      }

      return false
    },
  },
  methods: {
    ...mapMutations('agendamento/funcionarios', ['addFuncionario', 'setCurrentFuncionarioCpf', 'editFuncionario']),
    async nextStep() {
      if (this.step === 1) {
        if (!this.$refs.firstForm.validate()) return

        if (
          !this.$route.query.edit &&
          this.getFuncionarios.find(funcionario => funcionario.cpf === this.funcionario.cpf)
        ) {
          return this.showErrorOnSnackbar('Você já adicionou um funcionário com o mesmo CPF')
        }

        this.step = 2

        return
      }
      if (this.step === 2) {
        if (!this.$refs.secondForm.validate()) return
        this.step = 3
        return
      }

      if (this.step === 3) {
        if (!this.$refs.thirdForm.validate()) return
        this.step = 4
        return
      }

      if (this.step === 4 && !this.funcionario.exames) return

      if (this.tipoAgendamento === 'online') {
        if (this.hasExamesComplementares()) {
          this.escolherClinicaDialog = true
        } else {
          this.escolherHorarioDialog = true
        }
        return
      }
      this.finalizarCadastro()
    },
    verifyQuestion(value) {
      if (value.currentIndex === value.lastIndex) {
        this.lastQuestion = true
      } else {
        this.lastQuestion = false
      }
    },
    finalizarCadastro() {
      this.storeFuncionario()
      this.actualStepWrapper = 6
      this.$router.push({ name: 'colaborador' })
    },
    hasExamesComplementares() {
      return this.funcionario.exames.find(exame => exame.id_exame !== 305)
    },
    resolveSelectHorario(idHorario) {
      this.funcionario.idHorario = idHorario
    },
    resolveSelectCidade(cidade) {
      this.funcionario.cidade = cidade
    },
    resolveSelectedClinica(idClinica) {
      this.funcionario.idClinica = idClinica
    },
    storeFuncionario() {
      if (this.$route.query && this.$route.query.edit) {
        this.editFuncionario({ funcionario: this.funcionario, index: this.getCurrentFuncionarioIndex })
      } else {
        this.addFuncionario(this.funcionario)
      }
    },
    onUpdateFuncionario(funcionario) {
      this.funcionario = funcionario
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.edit) {
      this.funcionario = this.getCurrentFuncionario
    }
  },
}
</script>

<style scoped>
.v-stepper >>> .v-stepper__step__step {
  color: transparent;
}

.v-stepper >>> .v-stepper__header {
  box-shadow: none;
}
.v-stepper {
  background: transparent !important;
}
</style>
