<template>
  <v-row no-gutters align="center" justify="center" class="fill-height py-16" :class="{ 'mt-8': $vuetify.breakpoint.mdAndDown }">
    <v-col cols="10" lg="8" xl="6">
      <v-form v-model="valid" ref="form" @submit.prevent="nextStep" lazy-validation>
        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" class="head-title mb-2 text-center"> Insira o CNPJ da empresa </v-col>
          <v-col cols="12" class="subtitle mb-10 text-center" style="color: grey">
            Informe o CNPJ da empresa contratante e clique no ícone de lupa ao lado para pesquisar as informações.
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Digite seu CNPJ"
              outlined
              v-model="empresa.cnpj"
              :loading="loadingCnpj"
              :rules="[
                () => $v.empresa.cnpj.required || 'Campo obrigatório',
                () => $v.empresa.cnpj.validCnpj || 'CNPJ inválido',
              ]"
              v-mask="'##.###.###/####-##'"
              append-outer-icon="mdi-magnify"
              @click:append-outer="verificarCNPJ"
              @change="handleCnpj"
            ></v-text-field>
          </v-col>
          <v-col cols="8">
            <v-text-field
              label="Razão Social"
              outlined
              v-model="empresa.razao_social"
              :rules="[
                () => $v.empresa.razao_social.required || 'Campo obrigatório',
                () => $v.empresa.razao_social.minLength || 'Mínimo de 10 caracteres',
              ]"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pl-2">
            <v-text-field
              label="CEP"
              outlined
              v-model="empresa.endereco_cep"
              v-mask="'##.###-###'"
              :rules="[() => $v.empresa.endereco_cep.required || 'Campo obrigatório']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="pl-2">
            <v-text-field
              label="Número"
              outlined
              v-model="empresa.endereco_numero"
              :rules="[() => $v.empresa.endereco_cep.required || 'Campo obrigatório']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Rua"
              outlined
              v-model="empresa.endereco_rua"
              :rules="[() => $v.empresa.endereco_rua.required || 'Campo obrigatório']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pl-2">
            <v-text-field
              label="Bairro"
              outlined
              v-model="empresa.endereco_bairro"
              :rules="[() => $v.empresa.endereco_bairro.required || 'Campo obrigatório']"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="selectedCity"
              :items="cidades"
              item-text="nome"
              item-value="id_cidade"
              label="Selecione uma cidade"
              outlined
              clearable
              :loading="loadingCities"
              :rules="[() => $v.selectedCity.required || 'Campo obrigatório']"
              :disabled="disabled"
              @input="updateSelectedCity"
            >
              <template v-slot:item="{ item }"> {{ item.nome }} - {{ item.uf }} </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                rounded
                x-large
                class="elevation-12"
                @click="nextStep"
                :loading="loadingCities"
                :disabled="disabled"
              >
                <span class="capitalize">Seguir</span>
                <v-icon size="20" right>mdi-chevron-right-circle-outline</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import agendamento from '@/services/agendamento'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import validaCnpj from '@/utils/validaCnpj'
import agendamentoMixin from '@/mixins/agendamentoMixin'
import autenticacaoService from '@/services/autenticacao'

export default {
  mixins: [agendamentoMixin, validationMixin],
  validations: {
    empresa: {
      cnpj: {
        required,
        validCnpj(cnpj) {
          return !!cnpj && cnpj.length >= 18 && validaCnpj(cnpj)
        },
      },
      razao_social: {
        required,
        minLength: value => value.length >= 10,
      },
      endereco_cep: { required },
      endereco_numero: { required },
      endereco_rua: { required },
      endereco_bairro: { required },
    },
    selectedCity: { required },
  },
  data: () => ({
    valid: true,
    loadingCnpj: false,
    loadingCities: false,
    cidades: [],
    selectedCity: null,
    empresa: {
      cnpj: '',
      razao_social: '',
      endereco_cep: '',
      endereco_numero: '',
      endereco_rua: '',
      endereco_bairro: '',
      endereco_id_cidade: null,
    },
    idEmpresa: 0,
    disabled: true,
  }),
  computed: {
    ...mapGetters('agendamento/empresa', ['getCnpj', 'getEmpresa']),
  },
  methods: {
    validaCnpj(cpf) {
      if (process.env.VUE_APP_ENV === 'DEV') return true
      return validaCnpj(cpf)
    },
    ...mapMutations('agendamento/empresa', [
      'setEmpresa',
      'setEmpresaId',
      'setCnpj',
      'setEmpresaJaCadastrada',
      'setSegmentos',
    ]),
    async handleCnpj() {
      if (this.empresa.cnpj.length === 18) {
        this.verificarCNPJ()
      }
    },
    async verificarCNPJ() {
      if (!this.$v.empresa.cnpj) return

      this.loadingCnpj = true
      try {
        const { data } = await agendamento.verificaCnpj(this.empresa.cnpj)
        this.setEmpresaJaCadastrada(data.empresaJaCadastrada)
        this.setSegmentos(data.segmentos)
        this.empresa = data.empresa
        this.selectedCity = this.empresa.endereco_id_cidade

        if (data.empresaJaCadastrada) {
          this.setEmpresaId(data.idEmpresa)
        }
        this.setEmpresa(data.empresa)

        if (data.empresaJaCadastrada) {
          if (autenticacaoService.isAuthenticated()) {
            this.actualStepWrapper = 6
            this.$router.push({ name: 'colaborador' })
            return
          }

          this.actualStepWrapper = 5
          this.$router.push({ name: 'agendamento-perfil' })
        }

        this.disabled = false
      } catch (error) {
        this.showErrorOnSnackbar(error.response.data.message)
        if (error.response.status === 409) {
          setTimeout(() => (window.location.href = error.response.data.redirectTo), 2000)
        }
      } finally {
        this.loadingCnpj = false
      }
    },
    async nextStep() {
      if (!this.$refs.form.validate()) return

      this.setEmpresa(this.empresa)
      this.actualStepWrapper = 4
      this.$router.push({ name: 'empresa-segmento' })
    },
    async loadCidades(val) {
      this.loadingCities = true
      try {
        const { data } = await agendamento.buscaCidades({ nome: val })
        this.cidades = data
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingCities = false
      }
    },
    updateSelectedCity() {
      if (this.selectedCity) {
        this.empresa.endereco_id_cidade = this.selectedCity
      } else {
        this.empresa.endereco_id_cidade = null
      }
    },
  },
  beforeMount() {
    this.actualStepWrapper = 3
    this.empresa = this.getEmpresa
    this.selectedCity = this.empresa.endereco_id_cidade
    this.loadCidades('')
  },
}
</script>
